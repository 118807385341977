const n = null;

// prettier-ignore
export default [
    [n,n,n,n,n,n,n,n,n,n,n,n,n,n,n,n,n,n,n,n,n,n,n,n,n,n,n,n,n,n,n,n,n,n,n,1,1,1,1,1],
    [n,n,n,n,n,n,n,n,n,n,n,n,n,n,n,n,n,n,n,n,n,n,n,n,n,n,n,n,n,n,1,1,1,1,1,1,1,1,1,1],
    [n,n,n,n,n,n,n,n,n,n,n,n,n,n,n,n,n,n,n,n,n,n,n,n,n,n,1,1,1,1,1,1,1,1,1,1,1,1,1,1],
    [n,n,n,n,n,n,n,n,n,n,n,n,n,n,n,n,n,n,n,n,n,n,n,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1],
    [n,n,n,n,n,n,n,n,n,n,n,n,n,n,n,n,n,n,n,n,1,1,1,1,1,1,1,1,1,2,2,2,2,2,2,2,2,2,2,2],
    [n,n,n,n,n,n,n,n,n,n,n,n,n,n,n,n,1,1,1,1,1,1,1,1,1,1,2,2,2,2,2,3,3,3,3,3,3,3,4,4],
    [n,n,n,n,n,n,n,n,n,n,n,1,1,1,1,1,1,1,1,1,1,2,2,2,2,2,2,2,3,3,3,3,4,4,4,4,5,5,5,5],
    [n,n,n,n,n,n,1,1,1,1,1,1,1,1,1,1,1,1,2,2,2,2,2,2,3,3,3,3,4,4,4,5,5,5,6,6,7,7,7,7],
    [2,2,2,1,1,1,1,1,1,1,1,1,1,1,2,2,2,2,2,2,2,3,3,3,3,4,4,5,5,5,6,6,6,7,7,7,8,8,8,8],
    [3,3,3,3,3,3,2,2,2,2,2,3,3,3,3,2,2,2,3,3,3,4,4,4,5,5,6,6,7,7,8,8,8,8,9,9,9,9,9,9],
    [5,5,5,5,5,5,4,4,4,4,4,4,4,4,3,3,3,3,4,4,4,5,5,6,6,7,8,8,8,9,9,9,9,9,9,9,9,9,9,9],
    [7,7,7,7,7,7,6,6,6,5,5,5,5,5,4,4,5,5,6,6,7,7,7,8,8,8,9,9,9,9,9,9,9,9,9,9,9,9,9,9],
    [9,8,8,8,8,8,8,8,8,8,7,7,7,7,6,6,6,7,7,8,8,8,9,9,9,9,9,9,9,9,9,9,9,9,9,9,9,9,9,9]
];
