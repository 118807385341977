// React
import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';

// Emotion / styling
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import s from 'src/styles';

// Packages
import { useResizeObserver } from 'use-events';

const TilePatternComponent = ({ pattern, fill, parentHeight }) => {
    // Refs
    const tileWrapperRef = React.useRef(null);

    // Handle unit size
    const [tileWrapperWidth] = useResizeObserver(tileWrapperRef);
    const [unitSize, setUnitSize] = useState(
        tileWrapperWidth / pattern[0].length
    );
    useEffect(() => {
        setUnitSize(tileWrapperWidth / pattern[0].length);
    }, [tileWrapperWidth]);

    // Get mouse position
    const [mouse, setMouse] = useState({ x: 0, y: 0 });
    let handleMousemove = event => {
        setMouse({ x: event.x, y: event.y });
    };
    useEffect(() => {
        document.addEventListener('mousemove', handleMousemove);
        return () => document.removeEventListener('mousemove', handleMousemove);
    }, []);

    const canvas = useRef(null);

    // Point converter
    const pointConvert = point => {
        return point * unitSize;
    };

    // Buffer for positioning tiles
    const topBuffer = parentHeight - pattern.length * unitSize;

    // The render hook
    useLayoutEffect(() => {
        // Get canvas going
        const ctx = canvas.current.getContext('2d');

        // Clear canvas
        ctx.clearRect(0, 0, canvas.current.width, canvas.current.height);

        // Mouse
        const { x, y } = mouse;

        // Loop through pattern
        pattern.map((row, rowIndex) => {
            row.map((cell, cellIndex) => {
                // Base position based on cellIndex and rowIndex
                const basePosition = {
                    x: pointConvert(cellIndex),
                    y: pointConvert(rowIndex) + topBuffer,
                };

                // Calculate tileWidth based on mouse movement
                const dx = Math.abs(x - basePosition.x);
                const dy = Math.abs(y - basePosition.y);
                const distance = Math.sqrt(dx * dx + dy * dy);
                const tileWidth = cell
                    ? Math.min(((cell + 1) * distance) / 700, cell + 1)
                    : 0; // 700 is sensitivity on tilewidth animation

                // Start drawing
                ctx.beginPath();

                // First point (0, 0)
                ctx.moveTo(basePosition.x, basePosition.y);

                // Second point - top border (width, 0) (max width = unitSize)
                ctx.lineTo(
                    basePosition.x +
                        Math.min(pointConvert(tileWidth / 10), unitSize),
                    basePosition.y
                );

                // Third point - diagonal (0, tileWidth)
                ctx.lineTo(basePosition.x, basePosition.y + unitSize);

                // Close
                ctx.fill();

                // Color
                ctx.fillStyle = fill;
            });
        });
    }, [mouse, unitSize, parentHeight]);

    return (
        <>
            <TilePatternWrapper>
                <TilePattern ref={tileWrapperRef}>
                    <canvas
                        width={tileWrapperWidth}
                        height={parentHeight}
                        ref={canvas}
                    />
                </TilePattern>
            </TilePatternWrapper>
        </>
    );
};

const animateTilesIn = keyframes`
    0% {
        opacity: 0;
        transform: translateY(5vh);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
`;

const TilePatternWrapper = styled.div`
    position: absolute;
    left: -300px;
    right: -300px;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    overflow: hidden;
    transform: translateY(100vh);

    animation-name: ${animateTilesIn};
    animation-duration: 1500ms;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-delay: 1000ms;
    animation-timing-function: ${s.easings.smooth.out};
`;

const TilePattern = styled.div`
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    > div {
        pointer-events: none;
        > div {
            pointer-events: none;
        }
    }
`;

export default TilePatternComponent;
