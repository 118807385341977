// React
import React from 'react';

// Packages
import { useGlobal } from 'reactn';

// Utilities
import { useTouch, useResponsive } from 'hooks';

// Components
import TilePattern from './tilePattern.component';

// Patterns
import hero from './patterns/hero';
import standard from './patterns/default';

const TilePatternWrapperComponent = ({
    pattern,
    theme,
    parentHeight = null,
    maxHeight = null,
    darken = true,
}) => {
    // Define patters
    const patterns = { hero, standard };

    // Selected pattern
    const selectedPattern = patterns[pattern];
    const selectedPatternLength = selectedPattern.length;
    const treatedPattern = patterns[pattern].slice(
        0,
        maxHeight ? maxHeight : selectedPatternLength
    );

    // Fill
    const fill = darken ? theme.tile() : theme.default();

    const touch = useTouch();
    const breakpoint = useResponsive();

    const [ssr] = useGlobal('ssr');

    return ssr ? null : !touch && breakpoint > 20 ? (
        <TilePattern {...{ fill, pattern: treatedPattern, parentHeight }} />
    ) : null;
};

export default TilePatternWrapperComponent;
